import payload_plugin_awyBkvyjYq from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_B0cTsC1GcZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qNXSXx7p5D from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vwPBCS3zfW from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2ACa5OkQKP from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5._i3g5vaqwrwdvlqvjnwf4xp2ecy/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_NrMramPJnT from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vBRvYSJHyU from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IWGKq3vEBO from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zDJ3TIKA9p from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vpp2568g7T from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_iwDzbprJ7n from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_lZXKqtXcSK from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_O4ekS5GzVq from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_EhYSpiBhEa from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_HpZWMCBLbA from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_AeCpwNCY0I from "/vercel/path0/node_modules/.pnpm/@twicpics+components@0.29.3/node_modules/@twicpics/components/nuxt3/plugin.js";
import plugin_lSDjrxwod1 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37.0__kzmlkneuy44hpc2kn2wzylmvwq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import defaults_mNcOUp94Cl from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_awyBkvyjYq,
  revive_payload_client_B0cTsC1GcZ,
  unhead_qNXSXx7p5D,
  router_vwPBCS3zfW,
  _0_siteConfig_2ACa5OkQKP,
  payload_client_NrMramPJnT,
  navigation_repaint_client_vBRvYSJHyU,
  check_outdated_build_client_IWGKq3vEBO,
  chunk_reload_client_zDJ3TIKA9p,
  plugin_vue3_Vpp2568g7T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iwDzbprJ7n,
  siteConfig_lZXKqtXcSK,
  inferSeoMetaPlugin_O4ekS5GzVq,
  titles_EhYSpiBhEa,
  plugin_client_HpZWMCBLbA,
  floating_vue_EIcJ7xiw0h,
  plugin_AeCpwNCY0I,
  plugin_lSDjrxwod1,
  posthog_client_fUucxKWhZE,
  defaults_mNcOUp94Cl
]