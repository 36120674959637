import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as component_45stubgjNhJtgSRbMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgjNhJtgSRb } from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___zdqwnkqucx36fh7mehv3qnydu4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: component_45stubgjNhJtgSRbMeta?.name,
    path: "/articles",
    component: component_45stubgjNhJtgSRb
  },
  {
    name: component_45stubgjNhJtgSRbMeta?.name,
    path: "/articles/",
    component: component_45stubgjNhJtgSRb
  },
  {
    name: component_45stubgjNhJtgSRbMeta?.name,
    path: "/articles/:pathMatch(.*)",
    component: component_45stubgjNhJtgSRb
  },
  {
    name: component_45stubgjNhJtgSRbMeta?.name,
    path: "/blog/repository-pattern-in-composables",
    component: component_45stubgjNhJtgSRb
  }
]